import Plyr from "plyr";
import Swiper, { Navigation } from "swiper";

class Anim {
  constructor() {
    console.log("Anim");
    this.players = [];
    const vplayers = document.querySelectorAll(".videoplayer");
    vplayers.forEach((vplayer, n) => {
      this.players[n] = new Plyr(vplayer, {
        fullscreen: { enabled: true, fallback: true, iosNative: true },
        ratio: "16:9",
        settings: [],
        controls: [
          "play-large",
          "play",
          "captions",
          "progress",
          "volume",
          "fullscreen",
        ],
        autopause: true,
      });
      vplayer.setAttribute(
        "src",
        window.innerWidth > 1024
          ? vplayer.dataset.src
          : vplayer.dataset.srcmobile
      );
      this.players[n].on("canplay", (e) => {

      });
      this.players[n].on("playing", (event) => {
        const instance = event.detail.plyr;

        ym(88062334, "reachGoal", "playing", {
          videoID: instance.elements.original.id,
        });
      });
    });
    this.thumbs = document.querySelectorAll(".thumb");

    this.swiper = new Swiper(".swiper-container", {
      modules: [Navigation],
      // Optional parameters
      direction: "horizontal",
      loop: false,
      noSwipingClass: "plyr__controls",

      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

      on: {
        slideChange: (sw) =>{
          this.highlightThumb(sw.realIndex);
          const videos = document.querySelectorAll(".swiper-slide>iframe");

          this.players.forEach((video) => {
            video.pause();
          });
        },
      },
    });

    this.highlightThumb(0);

    this.thumbs.forEach((thumb) => {
      thumb.addEventListener(
        "click",
        ((event) => {
          var el = event.currentTarget;
          el.classList.add("clicked");
          setTimeout(function () {
            el.classList.remove("clicked");
          }, 200);
          const index = [...el.parentElement.children].indexOf(el);
          this.swiper.slideToLoop(index);
          this.highlightThumb(index);
          document.getElementById("framevideo").scrollIntoView();
        }).bind(this)
      );
    });

    /* document
      .querySelector("#section2 > a")
      .addEventListener("click", function (event) {
        var el = event.currentTarget;
        el.classList.add("clicked");
        setTimeout(function () {
          el.classList.remove("clicked");
        }, 200);
      }); */

    document
      .querySelectorAll(".swiper-button-prev,.swiper-button-next")
      .forEach((btn) =>
        btn.addEventListener("click", function (event) {
          var el = event.currentTarget;
          el.classList.add("clicked");
          setTimeout(function () {
            el.classList.remove("clicked");
          }, 200);
        })
      );
  }

  highlightThumb(index) {
    this.thumbs.forEach((thumb, n) => {
      if (n == index) thumb.classList.add("selected");
      else thumb.classList.remove("selected");
    });
  }
}

addEventListener("load", () => new Anim() );
